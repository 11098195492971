//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mnemonicValidate } from "@polkadot/util-crypto";
import LandingPagePopUp from "@/views/LandingPage/LandingPagePopUp.vue";
import errorMessage from "@/common/constants/error-messages";
import localStorage from "@/common/lib/local-storage";
export default {
  name: "InputMnemonic",
  components: {
    LandingPagePopUp
  },
  data: () => ({
    errorMessage,
    mnemonic: ""
  }),
  computed: {
    mnemonicRule() {
      if (!this.mnemonic) {
        return this.errorMessage.REQUIRED;
      }
      if (!mnemonicValidate(this.mnemonic)) {
        return this.errorMessage.INCORRECT("Mnemonic");
      }
      return true;
    },
    checkIfValid() {
      return this.mnemonicRule === true;
    }
  },
  methods: {
    previous() {
      this.$router.push({
        name: "sign-in"
      });
    },
    changePassword() {
      const dataAccountExist = localStorage.getKeystore() !== null;
      this.$router.push({
        name: "change-password",
        params: {
          mnemonic: this.mnemonic,
          openInfo: dataAccountExist ? "close" : "open"
        }
      });
    }
  }
};